<template>
  <div class="col-auto p-0 d-flex flex-column align-items-center">
    <JayaTitle classType="page-title" titleName="Mentions Légales"></JayaTitle>
    <b-card class="mt-1 text-center">
      <p><strong> Raison sociale </strong><br> JAYA SAS </p>
      <p><strong> Capital social </strong><br> 1000 euros </p>
      <p><strong> Adresse du siège social </strong><br> 23 rue Jean Baldassini 69007 Lyon 7eme </p>
      <p><strong> SIRET </strong><br>880 826 235 00017</p>
      <p><strong> APE </strong><br> J7219Z </p>
      <p><strong> TVA </strong><br> FR61880826235 </p>
      <p><strong> Email </strong><br> contact&#64;jaya-garden&#46;fr </p>
      <p><strong> Hébergeur du site </strong><br> OVH </p>
      <b-button class="mt-1" type="filled" variant="primary" @click="$router.push('/conditions-utilisation')">
        Conditions générales d'utilisation
      </b-button>
    </b-card>
  </div>
</template>

<script>
import JayaTitle from '@/components/global/JayaTitle.vue'
import {BButton, BCard} from "bootstrap-vue"

export default {
  components: {
    JayaTitle,
    BCard,
    BButton
  }
}
</script>
